import React from "react";
import styles from "./productCard.module.scss";
import { Card } from "react-bootstrap";
import Link from "next/link";
import { ImageWrapper } from "@/common/components/Images/ImageWrapper";
import { endStringEllipsis, formatNumberWithDecimals } from "@/common/helpers/util.functions";
import ProductStock from "../ProductStock/ProductStock";
import { ProductLabels } from "@/common/entities/product/Product";
import Image from "next/image";
import WarrantyIcon from "@/images/warranty-icon.png";

interface ProductCardProps {
    imageSrc?: string;
    productName: string;
    price: number;
    oldPrice?: number;
    shortDescription: string;
    stock: number;
    currency: string;
    className?: string;
    url: string;
    displayPrice: boolean;
    productLabels?: ProductLabels;
    availableToOrder: boolean;
}

const ProductCard = ({
    imageSrc,
    productName,
    price,
    oldPrice,
    shortDescription,
    stock,
    className = "",
    currency,
    displayPrice,
    url,
    availableToOrder,
    productLabels,
}: ProductCardProps) => {
    return (
        <Link className={className} href={url}>
            <Card className={styles.productCard}>
                <div className={styles.imageWrapper}>
                    <ImageWrapper className={styles.mainImg} width={240} height={240} url={imageSrc} title={productName} />
                    {productLabels && (
                        <>
                            <div className={styles.labelIcons}>
                                {productLabels?.warranty && (
                                    <div className={styles.icon}>
                                        <div className={styles.warranty}>
                                            <Image width={40} height={40} src={WarrantyIcon.src} alt="warranty" />
                                            <div className={styles.value}>
                                                {productLabels.warranty}
                                                <div className={styles.afterValue}>ANI</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {productLabels?.pro && (
                                    <div className={styles.icon}>
                                        <Image width={40} height={40} src={productLabels.pro} alt="pro" />
                                    </div>
                                )}
                                {productLabels?.ecoDesign && (
                                    <div className={styles.icon}>
                                        <Image width={40} height={40} src={productLabels.ecoDesign} alt="eco design" />
                                    </div>
                                )}
                            </div>
                            {productLabels?.energyClass && (
                                <div className={styles.energyClassIcon}>
                                    <Image width={40} height={20} src={productLabels.energyClass} alt="energy class" />
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className={styles.productCardInfo}>
                    <h2 className={styles.productName}>{productName}</h2>
                    <div>
                        <ProductStock availableToOrder={availableToOrder} availableQty={stock} />
                    </div>
                    <div className={styles.productDescription} dangerouslySetInnerHTML={{ __html: endStringEllipsis(shortDescription, 88) }}></div>

                    {displayPrice ? (
                        <div className={`${styles.productPriceWrapper} mt-2`}>
                            <div className={styles.productOldPrice}>
                                {oldPrice && (
                                    <span>
                                        {formatNumberWithDecimals(oldPrice)} {currency}
                                    </span>
                                )}
                            </div>
                            <div className={styles.productPrice}>
                                {formatNumberWithDecimals(price)} {currency}
                            </div>
                        </div>
                    ) : (
                        <div className={`${styles.productPriceWrapper} mt-2`}>
                            <div className={styles.productPrice}>Cerere oferta</div>
                        </div>
                    )}
                </div>
            </Card>
        </Link>
    );
};

export default ProductCard;
